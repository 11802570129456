@import 'src/styles/variables';
@import 'src/styles/utils';

.MerchantHeader {
  position: relative;

  .MerchantHeaderWithCoverImage__Background {
    height: layout-unit(8);
    background-color: #adadad;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .MerchantHeaderWithCoverImage__SummaryBg {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 33%;
    background: $color-background;
  }

  .MerchantHeaderWithCoverImage__Summary {
    display: flex;
    position: relative;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: layout-unit(1.5) layout-unit(1);
  }

  .MerchantHeaderWithCoverImage__SummaryLink {
    display: flex;
    width: 70%;
    text-decoration: none;
  }

  .MerchantHeaderWithCoverImage__SummaryThumbnail {
    padding-right: layout-unit(1);
  }

  .MerchantHeaderWithCoverImage__ThumbnailImage {
    object-fit: cover;
    width: layout-unit(3);
    height: layout-unit(3);
    border-radius: 50%;
  }

  .MerchantHeaderWithCoverImage__SummaryText {
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  .MerchantHeaderWithCoverImage__Name {
    margin: 0 0 2px;
    color: $color-black;
    font-size: 16px;
    font-weight: 600;
  }

  .MerchantHeaderWithCoverImage__SummaryRight {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .MerchantHeaderWithCoverImage__SummaryBook {
    display: none;
  }

  // iphone 414px
  @media screen and (min-width: 414px) {
    .MerchantHeaderWithCoverImage__Background {
      height: 138px;
    }
  }

  @include media(tab) {
    .MerchantHeaderWithCoverImage__Background {
      height: layout-unit(12);
    }

    .MerchantHeaderWithCoverImage__Name {
      font-size: 20px;
    }
  }

  // ipad 768px
  @media screen and (min-width: 768px) {
    .MerchantHeaderWithCoverImage__Background {
      height: layout-unit(16);
    }
  }

  @include media(pc) {
    width: 100%;
    max-width: $size-content-width-pc;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 4px;

    .MerchantHeaderWithCoverImage__Background {
      height: layout-unit(23.75);
    }

    .MerchantHeaderWithCoverImage__SummaryBg {
      position: absolute;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
    }

    .MerchantHeaderWithCoverImage__Summary {
      position: relative;
      max-width: $size-content-width-pc;
      margin: 0 auto;
      padding: layout-unit(1.5);
    }

    .MerchantHeaderWithCoverImage__SummaryThumbnail {
      padding-right: layout-unit(1.5);
    }

    .MerchantHeaderWithCoverImage__SummaryBook {
      display: block;
    }

    .MerchantHeaderWithCoverImage__SummaryFavorite {
      width: layout-unit(7);
      text-align: center;
    }

    .MerchantHeaderWithCoverImage__ThumbnailImage {
      width: layout-unit(4);
      height: layout-unit(4);
    }

    .MerchantHeaderWithCoverImage__Name {
      color: $color-white;
      font-size: 24px;
    }
  }

  .MerchantHeaderWithoutCoverImage__Background {
    height: layout-unit(8);
    background-color: #adadad;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .MerchantHeaderWithoutCoverImage__SummaryBg {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 33%;
    background: $color-background;
  }

  .MerchantHeaderWithoutCoverImage__Summary {
    display: flex;
    position: relative;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: layout-unit(1.5) layout-unit(1);
  }

  .MerchantHeaderWithoutCoverImage__SummaryLink {
    display: flex;
    width: 70%;
    text-decoration: none;
  }

  .MerchantHeaderWithoutCoverImage__SummaryThumbnail {
    padding-right: layout-unit(1);
  }

  .MerchantHeaderWithoutCoverImage__ThumbnailImage {
    object-fit: cover;
    width: layout-unit(3);
    height: layout-unit(3);
    border-radius: 50%;
  }

  .MerchantHeaderWithoutCoverImage__SummaryText {
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  .MerchantHeaderWithoutCoverImage__Name {
    margin: 0 0 2px;
    color: $color-black;
    font-size: 16px;
    font-weight: 600;
  }

  .MerchantHeaderWithoutCoverImage__SummaryRight {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .MerchantHeaderWithoutCoverImage__SummaryBook {
    display: none;
  }

  @include media(pc) {
    width: $size-content-width-pc;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 4px;

    .MerchantHeaderWithoutCoverImage__Summary {
      position: relative;
      width: auto;
      margin: 8px 0 24px 272px;
      padding: 0;
    }
  }
}
