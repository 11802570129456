@import 'src/styles/utils';

.GlobalNav {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  color: #333;

  .GlobalNav__Inner {
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    margin: 0 auto;
    padding: layout-unit(0.5) layout-unit(1);
  }

  .GlobalNav__Logo {
    display: inline-block;
    height: layout-unit(2);
    padding: 10px 0;
    line-height: 1;
    display: grid;
    place-items: center;
  }

  .GlobalNav__LogoImage {
    max-height: layout-unit(2);
    max-width: layout-unit(17.875);
    transition: fill 0.1s ease;
    fill: #333;
    vertical-align: bottom;
  }

  .GlobalNav__Buttons {
    display: flex;
    align-items: center;
  }

  .GlobalNav__UserMenu {
    position: relative;
  }

  .GlobalNav__UserIconLink {
    display: inline-block;
    box-sizing: border-box;
    width: layout-unit(2.5);
    height: layout-unit(2.5);
    padding: layout-unit(0.25);
  }

  .GlobalNav__UserIcon {
    display: inline-block;
    box-sizing: border-box;
    width: layout-unit(2);
    height: layout-unit(2);
    border-radius: 50%;
    background-size: contain;
  }

  .GlobalNav__UserDropMenu {
    position: absolute;
    z-index: 1;
    right: 0;
    width: layout-unit(12);
    margin: 0;
    padding: 0;
    background: #fff;
  }

  .GlobalNav__Button {
    display: none;
    white-space: nowrap;

    @include media(pc) {
      display: inline-flex;
    }
  }

  .GlobalNav__TextLink {
    display: none;
    padding: layout-unit(0.75) layout-unit(1);
    transition: color 0.1s ease;
    color: #333;
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: #cdcdcd;
    }

    @include media(pc) {
      display: inline-block;
    }
  }

  .GlobalNav__MenuButton {
    display: inline-block;
    width: layout-unit(1.5);
    height: layout-unit(1.5);
    padding: layout-unit(0.5) 0;
    transition: fill 0.1s ease;
    cursor: pointer;

    &:hover {
      fill: #cdcdcd;
    }

    @include media(pc) {
      display: none;
    }
  }

  .GlobalNav__DisplayName {
    padding: layout-unit(0.75) 0;
    color: #333;
    font-size: 13px;
    font-weight: bolder;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media(pc) {
      display: inline-block;
    }
  }

  .GlobalNav__LoginButtonWrapper {
    display: none;

    @include media(pc) {
      display: inline-block;
    }
  }
}
