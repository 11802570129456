@import 'src/styles/utils';

.MerchantNav {
  display: flex;
  position: sticky;
  z-index: 4;
  top: 0;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style: none;

  .MerchantNav__Item {
    width: 100%;
  }

  .MerchantNav__Link {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    transition: border 0.25s ease-in-out, background-color 0.25s ease-in-out;
    background-clip: border-box;
    background-color: #f0f0f0;
    color: #888;
    font-size: 12px;
    letter-spacing: -0.05em;
    line-height: 1.3;
    text-align: center;
    text-decoration: none;
    white-space: pre-wrap;

    &:focus {
      outline: none;
    }

    &.isActive {
      border-bottom: 3px solid #1b1b1b;
      background-color: #fff;
      color: #333;
      font-weight: 600;
    }
  }

  @include media(pc) {
    position: static;
    flex-direction: column;
    flex-shrink: 0;
    width: layout-unit(15);
    border-bottom: 1px solid #ddd;

    .MerchantNav__Item {
      border-top: 1px solid #ddd;
    }

    .MerchantNav__Link {
      justify-content: flex-start;
      height: layout-unit(4);
      padding-left: layout-unit(1.5);
      transition: border-left 0.25s ease;
      border-left: 4px solid transparent;
      background-color: transparent;
      font-size: 14px;

      &:hover {
        border-left: 4px solid #1b1b1b;
        background-color: #fff;
        color: #333;
        font-weight: 600;
      }

      &.isActive {
        border-bottom: none;
        border-left: 4px solid #1b1b1b;
      }
    }
  }
}

.MerchantNav__HideOnMobile {
  display: none;

  @include media(pc) {
    display: block;
  }
}
