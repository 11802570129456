@import 'src/styles/variables';
@import 'src/styles/utils';

.Heading {
  margin: 0;
  padding: layout-unit(0.25) 13px;
  border-left: 3px solid $color-brand;
  color: $color-black;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;

  @include media(pc) {
    padding: layout-unit(0.25) 12px;
    border-left: 4px solid $color-brand;
    font-size: 16px;
  }
}
