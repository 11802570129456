.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 80px;
  background: #ffebeb;
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}

.warning_icon {
  color: #e0091b;
  width: 32px;
  height: 32px;
}

.text {
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #e0091b;
}

.paragraph {
  margin: 0;
}

.link {
  color: #e0091b;
  text-decoration: underline;
  text-decoration-color: #e0091b;
}
