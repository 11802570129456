@import 'src/styles/utils';
@import 'src/styles/variables';

.base_layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-background;
}

.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon {
  margin-bottom: 32px;
}

.preparing_text {
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8599ad;
}

.footer {
  margin-top: auto;
}
