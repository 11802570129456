@import 'src/styles/variables';
@import 'src/styles/utils';

.GlobalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f0f0f0;
}
