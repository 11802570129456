@import 'src/styles/variables';
@import 'src/styles/utils';

.SiteFooter {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: layout-unit(1) layout-unit(1);
  background: $color-black;
  color: $color-white;
  font-size: 14px;

  @include media(pc) {
    height: layout-unit(6);
  }

  .SiteFooter__Inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $size-content-width-pc;

    @include media(pc) {
      flex-direction: row;
      align-items: center;
    }
  }

  .SiteFooter__Copyright {
    order: 3;
    padding: layout-unit(1) 0;

    @include media(pc) {
      order: 1;
      margin-right: auto;
    }
  }

  .SiteFooter__Links {
    display: flex;
    flex-wrap: wrap;
    order: 1;

    @include media(pc) {
      order: 2;
    }
  }

  .SiteFooter__Link {
    display: inline-block;
    margin-right: layout-unit(1.5);
    padding: layout-unit(0.5) 0;
    transition: opacity 0.1s ease;
    color: $color-white;
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }
  }

  .SiteFooter__Language {
    display: flex;
    align-items: center;
    order: 2;
    padding: layout-unit(1) 0;

    @include media(pc) {
      order: 3;
    }

    > * {
      display: inline-block;
      margin-right: layout-unit(0.5);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .SiteFooter__LanguageSelect {
    appearance: none;
    padding: layout-unit(0.5);
    border: 1px solid $color-white;
    outline: none;
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    cursor: pointer;
  }
}
