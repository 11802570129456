@import 'src/styles/variables';
@import 'src/styles/utils';

.GlobalNavModalMenu {
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: layout-unit(1);
  background-image: $gradient-brand;
  color: $color-white;

  .GlobalNavModalMenu__Button {
    width: 100%;
    max-width: layout-unit(20);

    + .GlobalNavModalMenu__Button {
      margin-top: layout-unit(1);
    }
  }

  .GlobalNavModalMenu__Close {
    position: absolute;
    top: 0;
    right: 0;
    width: layout-unit(1.5);
    height: layout-unit(1.5);
    padding: layout-unit(1);
    transition: opacity 0.1s ease;
    fill: $color-white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
