@import 'src/styles/variables';
@import 'src/styles/utils';

.BaseLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-background;

  .BaseLayout__Footer {
    margin-top: auto;
  }
}
