@import 'src/styles/utils';
@import 'src/styles/variables';

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  margin: 0;
  padding: 0 18px;
  transition: opacity 0.1s ease;
  border: none;
  border-radius: 4px;
  background-clip: border-box;
  background-color: $color-brand;
  background-image: $gradient-brand;
  color: $color-white;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &.isFull {
    width: 100%;
    padding: 0;
  }

  &.isGhost {
    border: 1px solid $color-brand;
    background: transparent;
    color: $color-brand;
  }

  &.isXSmall {
    min-height: 32px;
  }

  &.isMedium {
    min-height: 40px;
    font-size: 14px;
  }

  &.isLarge {
    min-height: 48px;
    font-size: 16px;
  }

  &.isXLarge {
    min-height: 56px;
    font-size: 16px;
  }

  &.isBiz {
    background: $color-biz-brand;
    background-image: $gradient-biz-brand;

    &.isGhost {
      border: 1px solid $color-biz-brand;
      background: transparent;
      color: $color-biz-brand;
    }
  }

  &.isWhite {
    background: $color-white;
    color: $color-brand;

    &.isGhost {
      border: 1px solid $color-white;
      background: transparent;
      color: $color-white;
    }
  }

  &.isWhiteBiz {
    background: $color-white;
    color: $color-biz-brand;

    &.isGhost {
      border: 1px solid $color-white;
      background: transparent;
      color: $color-white;
    }
  }

  &:focus {
    outline: none;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
