@import 'src/styles/variables';
@import 'src/styles/utils';

.SiteFooter {
  width: 100%;
  background: $color-white;

  .SiteFooter__Inner {
    box-sizing: border-box;
    max-width: $size-content-width-pc;
    margin: 0 auto layout-unit(5);
    padding: 0 layout-unit(1);
  }

  .SiteFooter__Section + .SiteFooter__Section {
    border-top: 1px solid $color-border;
  }

  .SiteFooter__NavBox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-top: layout-unit(3);
  }

  .SiteFooter__Nav {
    margin-right: layout-unit(2.5);
  }

  .SiteFooter__NavTitle {
    margin-bottom: layout-unit(2);
    color: #8599ad;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1em;
  }

  .SiteFooter__NavList {
    width: layout-unit(20);
    margin: 0;
    padding: 0 0 layout-unit(3);
    list-style: none;
  }

  .SiteFooter__NavListItem {
    margin-bottom: layout-unit(1.5);
  }

  .SiteFooter__NavListLink {
    transition: all 0.2s ease;
    color: $color-navy;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-decoration: none;
  }

  .SiteFooter__BottomNav {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: layout-unit(3) 0 0;
  }

  .SiteFooter__BottomNavList {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    padding: 0;
    list-style: none;
  }

  .SiteFooter__BottomNavListItem {
    margin: 0 0 layout-unit(2.5);
  }

  .SiteFooter__Copyright {
    color: $color-navy;
    font-size: 14px;
    letter-spacing: 0.1em;
  }

  @include media(pc) {
    .SiteFooter__Inner {
      max-width: $size-content-width-pc;
    }

    .SiteFooter__BottomNav {
      flex-flow: row;
      align-items: center;
    }

    .SiteFooter__BottomNavList {
      flex-flow: row;
      align-items: center;
    }

    .SiteFooter__BottomNavListItem {
      margin: 0 layout-unit(2.5) 0 0;
    }
  }
}
