@import 'src/styles/variables';
@import 'src/styles/utils';

.UserMenu {
  position: relative;

  .UserMenu__IconLink {
    display: inline-block;
    box-sizing: border-box;
    width: layout-unit(2.5);
    height: layout-unit(2.5);
    padding: layout-unit(0.25);
  }

  .UserMenu__Icon {
    width: layout-unit(2);
    height: layout-unit(2);
    border-radius: 50%;
    object-fit: cover;
  }

  .UserMenu__DropMenu {
    position: absolute;
    z-index: 100;
    top: layout-unit(3);
    right: 0;
    width: layout-unit(12);
    margin: 0;
    padding: 0;
    background: $color-white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    color: $color-black;
    font-size: 14px;
    list-style: none;
  }

  .UserMenu__DropMenuListItem {
    + .UserMenu__DropMenuListItem {
      border-top: 1px solid $color-border;
    }
  }

  .UserMenu__DropMenuProfile {
    display: flex;
    align-items: center;
    padding: layout-unit(1);
    color: $color-black;
    text-decoration: none;
  }

  .UserMenu__DropMenuProfile:hover {
    background: #eee;
  }

  .UserMenu__DropMenuProfileName {
    margin-left: layout-unit(1);
  }

  .UserMenu__DropMenuItem {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: layout-unit(1);
    transition: all 0.1s ease;
    border: none;
    outline: none;
    color: $color-black;
    font-size: 14px;
    text-decoration: none;
    appearance: none;
    cursor: pointer;
  }

  .UserMenu__DropMenuItem:hover {
    background: #eee;
  }
}
