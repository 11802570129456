@import 'src/styles/variables';
@import 'src/styles/utils';

.BaseTemplate {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background: $color-background;

  .BaseTemplateWithCoverImage__Body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .BaseTemplateWithoutCoverImage__Body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .BaseTemplate__AdsSection {
    padding: layout-unit(1) 0;
    text-align: center;
  }

  .BaseTemplate__UpgradeSection {
    padding: layout-unit(1);
    text-align: center;
  }

  .BaseTemplate__Button {
    box-sizing: border-box;
    max-width: 370px;
    padding: layout-unit(0.5) layout-unit(0.25);
  }

  @include media(pc) {
    .BaseTemplate__Header {
      margin-top: layout-unit(1);
    }

    .BaseTemplateWithCoverImage__Body {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: $size-content-width-pc;
      margin: 0 auto;
      padding: layout-unit(3) layout-unit(1);
    }

    .BaseTemplateWithoutCoverImage__Body {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: $size-content-width-pc;
      margin: 0 auto;
      padding: 0 layout-unit(1) layout-unit(3) layout-unit(1);
    }

    .BaseTemplate__Content {
      box-sizing: border-box;
      flex-grow: 1;
      padding-left: layout-unit(1);
    }
  }
}
