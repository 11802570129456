@import 'src/styles/variables';
@import 'src/styles/utils';

.Error {
  position: relative;
  width: 100%;
  height: 100vh;
  background: $color-white;

  .Error__Message {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 340px;
    margin: 0 auto;
    padding: 30px 0 0;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .Error__SubTitle {
    margin: 0 0 3px;
    color: #666;
    font-size: 13px;
    font-weight: bold;
  }

  .Error__Title {
    margin: 0 0 5px;
    color: #666;
    font-size: 36px;
    font-weight: bold;
  }

  .Error__Text {
    margin: 0;
    color: #666;
    font-size: 12px;
    line-height: 1.75;
    white-space: pre-wrap;
  }

  .Error__Image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;

    > img {
      width: 100%;
      height: auto;
    }
  }

  @include media(pc) {
    .Error__Message {
      width: 330px;
      height: 380px;
    }
  }
}
